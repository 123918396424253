"use client"

/* eslint-disable react-hooks/exhaustive-deps */

import dynamic from 'next/dynamic'
import PropTypes from 'prop-types';
import { useState } from 'react';
import Style from "../styles/Auth.module.scss"
import { useRouter, useSearchParams } from 'next/navigation';


const AuthCheckInbox = dynamic(() => import("components/applications/my_account/AuthCheckInbox"))
const AuthEmail = dynamic(() => import("components/applications/my_account/AuthEmail"))
const AuthForgotPassword = dynamic(() => import("components/applications/my_account/AuthForgotPassword"))
const AuthPassword = dynamic(() => import("components/applications/my_account/AuthPassword"))
const AuthRegister = dynamic(() => import("components/applications/my_account/AuthRegister"))
const OauthCheck = dynamic(() => import("components/applications/my_account/OauthCheck"))


function Auth({ authData }){
    const router = useRouter()
    const searchParams = useSearchParams();

    function returnUrl(){
        const returnParam = searchParams.get('return');
        return returnParam === null || returnParam === '/' ? '/' : `${returnParam}?refresh=true`;
    }

    const redirect = () => {
        const url = returnUrl()
        router.push(url ?? "/");
        router.refresh();
    }


    const [show, setShown] = useState({
        googleLogin:true,
        email: true,
        password: false,
        forgotPassword: false,
        register: false,
        checkInbox: false,
        magicLink: false
    })

    const display = (displayModule) => {
        setShown({...show, ...displayModule})
    }
    return(
        <div className={Style.block}>

            {/* Email Address */}
            {show.email && (
                <>
                    <AuthEmail setShown={display} />

                    {show.googleLogin && <OauthCheck setShown={display} />}
                </>
            )}


            {/* Register */}
            {show.register && (
                <>
                    <AuthRegister setShown={display} redirect={redirect} />

                    {show.googleLogin && <OauthCheck setShown={display} />}
                </>
            )}


            {/* Password */}
            {show.password && (
                <>
                    <AuthPassword setShown={display} redirect={redirect} />

                </>
            )}


            {/* Forgot Password */}
            {show.forgotPassword && (
                <AuthForgotPassword setShown={display} />
            )}


            {/* Check Your Inbox */}
            {show.checkInbox && (
                <AuthCheckInbox setShown={display} show={show} />
            )}
        </div>
    )
}


Auth.propTypes = {
    redirect: PropTypes.func
}


export default Auth;